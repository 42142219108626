import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { icons } from 'common/UI/Icon'

import styles from './Image.module.scss'

export const Image = (props) => {
  const [imgLoadError] = useState(false)

  const imageLoadedClass = classNames(styles.image, {
    [props.className || '']: props.className,
  })

  const imageLoaded = (
    <div
      style={{ backgroundImage: `url(${props.url})` }}
      className={imageLoadedClass}
      data-testid="img"
      // onError={() => setImgLoadError(true)}
      onClick={() =>
        !props.hidePreview &&
        window.globals?.service?.preview([{ url: props.url, type: 'image' }])
      }
      role="presentation"
    />
  )

  const imageFailed = (
    <div className={imageLoadedClass}>
      <img
        src={icons.picture}
        alt={props.alt}
        className={styles.imageError}
        data-testid="img"
      />
    </div>
  )

  return !imgLoadError ? imageLoaded : imageFailed
}

Image.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  hidePreview: PropTypes.bool,
}
