import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

import i18n from 'lib/i18n'

import { getLocalTimeZone } from './timezones'

export const agoTime = (date, format = '') => {
  dayjs.extend(advancedFormat)
  dayjs.extend(customParseFormat)
  dayjs.extend(relativeTime)
  const dateObj = dayjs(date).format(format)
  return dayjs(dateObj).fromNow()
}

export const isFutureDate = (date) => {
  const _date = dayjs(date)
  const isAfter = _date.isAfter()
  return isAfter
}

export const isPastDate = (date) => {
  const _date = dayjs(date)
  const isBefore = _date.isBefore()
  return isBefore
}

export const formatDate = (
  date,
  format = 'YYYY-MM-DD',
  outputFormat = 'MMMM Do'
) => {
  dayjs.extend(advancedFormat)
  dayjs.extend(customParseFormat)
  if (!format) {
    return dayjs(date).format(outputFormat)
  } else {
    return dayjs(date, format).format(outputFormat)
  }
}

export const checkDateWithinTimeperiod = (date, noOfHours) => {
  // date => timestamp in milliseconds
  // not converting to Hours, for precision (using timestamp)
  // 8.64e7 milliseconds => 24 Hours
  // 3.6e6 milliseconds => 1 Hour
  const hours = noOfHours * 3.6e6
  const diffHours = dayjs().diff(dayjs(date))
  return diffHours < hours
}

//function will return true if both the timestamps are in same day
export const isSameDay = (fromDate, toDate) => {
  return dayjs(fromDate).isSame(dayjs(toDate), 'day')
}

const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const months = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}

//pass a dayjs date to this function and it will return the list of months from that date to current date.
//used in Billing Reports Date Filter
export const getMonthsList = (dateFrom) => {
  let date = dayjs(dateFrom)
  let months = []
  while (dayjs().isAfter(date)) {
    months.push({
      label: dayjs(date).format('MMMM YY'),
      value: date,
      year: dayjs(date).format('YYYY'),
      month: dayjs(date).format('M'),
      isChecked: dayjs().isSame(date, 'month'),
    })
    date = new dayjs(date).add(1, 'months')
  }

  return months
}

//function will return Today, Yesterday, Week days, date by calculating diff between date and current date.
export const calendarTime = (date) => {
  if (!date) return null
  let diff = dayjs().endOf('day').diff(date, 'day')
  let displayDate = ''
  switch (diff) {
    case 0:
      displayDate = 'Today'
      break
    case 1:
      displayDate = 'Yesterday'
      break
    case 2:
    case 3:
    case 4:
    case 5:
      displayDate = weekdays[dayjs(date).day()]
      break
    default:
      displayDate = dayjs(date).format('MMM Do YYYY')
  }

  return displayDate
}

export const getCurrentTime = (is12Hours) => {
  const today = dayjs()
  const format = is12Hours ? 'hh:mm' : 'HH:mm'
  const time = formatDate(today, '', format)

  return time
}

export const dateRangeValues = {
  TODAY: 'TODAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
  CUSTOM: 'CUSTOM',
}

export const dateRangeDropdownOptions = [
  {
    label: i18n.t('analytics.header.tabs.today'),
    value: dateRangeValues.TODAY,
    dateFrom: () => new Date(dayjs()?.startOf('day')),
    dateTo: () => new Date(dayjs()?.endOf('day')),
    frequency: 'hour',
  },
  {
    label: i18n.t('analytics.header.tabs.week'),
    value: dateRangeValues.WEEK,
    dateFrom: () => new Date(dayjs().startOf('week')),
    dateTo: () => new Date(dayjs()?.endOf('day')),
    frequency: 'day',
  },
  {
    label: i18n.t('analytics.header.tabs.month'),
    value: dateRangeValues.MONTH,
    dateFrom: () => new Date(dayjs().startOf('month')),
    dateTo: () => new Date(dayjs()?.endOf('day')),
    frequency: 'day',
  },
  {
    label: i18n.t('analytics.header.tabs.year'),
    value: dateRangeValues.YEAR,
    dateFrom: () => new Date(dayjs().startOf('year')),
    dateTo: () => new Date(dayjs()?.endOf('day')),
    frequency: 'month',
  },
  {
    label: i18n.t('analytics.header.tabs.custom'),
    value: dateRangeValues.CUSTOM,
    dateFrom: () => new Date(dayjs().startOf('month')),
    dateTo: () => new Date(dayjs()?.endOf('day')),
    frequency: '',
  },
]

export const initialDateFilterData = {
  timePeriod: dateRangeValues.MONTH,
  dateFrom: new Date(dayjs().startOf('month')),
  dateTo: new Date(dayjs()?.endOf('day')),
  timeFrom: dayjs()?.startOf('day')?.format('HH:mm'),
  timeTo: dayjs()?.endOf('day')?.format('HH:mm'),
  timezone: getLocalTimeZone(),
  frequency: 'day',
}
