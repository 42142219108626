const routes = {
  INITIAL: '/',
  CHAT: '/chat',
  QUEUE: '/queue',
  LOGIN: '/login',
  SETTINGS: '/settings',
  APPS: '/apps',
  FORGOT_PASSWORD: '/forgot-password',
  FLOWS: '/flows',
  SIGNUP: '/signup',
  SIGNOUT: '/signout',
  OVERVIEW: '/overview',
  SHOPIFY_WELCOME: '/welcome',
  SHOPIFY_INSTALL: '/shopifyinstall',
  WIDGET: '/widget',
  APPCLIP: '/appclip',
}

export const settingsRoutes = {
  BILLING: `${routes.SETTINGS}/billing`,
  QUICKREPLY: `${routes.SETTINGS}/quickreplies`,
  CONTACTS: `${routes.SETTINGS}/contacts`,
  PROFILE: `${routes.SETTINGS}/profile`,
  ACCOUNT_SETTINGS: `${routes.SETTINGS}/account`,
  WEB_HOOKS: `${routes.SETTINGS}/webhooks`,
  APIKEYS: `${routes.SETTINGS}/api-keys`,
  TEAM_MANAGEMENT: `${routes.SETTINGS}/teams`,
  AGENT_MANAGEMENT: `${routes.SETTINGS}/agents`,
  BUSINESS_MESSAGES: `${routes.SETTINGS}/greeting-and-away-messages`,
  INTEGRATIONS: `${routes.SETTINGS}/integrations`,
  MESSAGE_LOGS: `${routes.SETTINGS}/message-logs`,
}

export const overviewRoutes = {
  DASHBOARD: routes.OVERVIEW,
  MARKETING_OVERVIEW: `${routes.OVERVIEW}/marketing`,
}

export const appsRoutes = {
  QUICK_REPLIES: `${routes.APPS}/quick_replies`,
  BROADCAST: `${routes.APPS}/broadcast`,
  TEMPLATES: `${routes.APPS}/templates`,
  ANALYTICS: `${routes.APPS}/analytics`,
  POPUP_CAMPAIGN: `${routes.APPS}/popup_campaign`,
  LINK_CREATOR: `${routes.APPS}/link-creator`,
  RULES_ENGINE: `${routes.APPS}/rules-engine`,
  SEGMENTS: `${routes.APPS}/segments`,
  CLICK_TO_CHAT: `${routes.APPS}/click-to-chat`,
  CLICK_TO_WHATSAPP: `${routes.APPS}/click-to-whatsapp-ads`,
  APPSLIST: '',
}

export const BROADCAST_ROUTES = {
  DETAILS: `${appsRoutes.BROADCAST}/:broadcastId`,
}

export const CLICK_TO_WHATSAPP_ROUTES = {
  DETAILS_OVERVIEW: `${appsRoutes.CLICK_TO_WHATSAPP}/:adId`,
  DETAILS_DATA: `${appsRoutes.CLICK_TO_WHATSAPP}/:adId/data`,
}

export const FLOW_ROUTES = {
  NEW_FLOW: `${routes.FLOWS}/new`,
  BUILDER_NEW: `${routes.FLOWS}/builder`,
  BUILDER_OVERVIEW: `${routes.FLOWS}/builder/:id`,
  BUILDER_EDIT: `${routes.FLOWS}/builder/:id/edit`,
  BUILDER_ANALYTICS: `${routes.FLOWS}/builder/:id/analytics`,
}

export const analyticsRoutes = {
  OVERVIEW: `${appsRoutes.ANALYTICS}`,
  CHAT_REPORTS: `${appsRoutes.ANALYTICS}/chat-reports`,
  AGENT_REPORTS: `${appsRoutes.ANALYTICS}/agent-reports`,
  AGENT_REPORT_DETAIL: `${appsRoutes.ANALYTICS}/agent-reports/:id`,
}

export const integrationsApps = {
  SHOPIFY_APP: `${settingsRoutes.INTEGRATIONS}/shopify`,
  FACEBOOK_APP: `${settingsRoutes.INTEGRATIONS}/facebook`,
  INSTAGRAM_APP: `${settingsRoutes.INTEGRATIONS}/instagram`,
  ADSMANAGER: `${settingsRoutes.INTEGRATIONS}/adsmanager`,
  ECOMMERCE: `${settingsRoutes.INTEGRATIONS}/ecommerce`,
}

export const shopifyAppRoutes = {
  SHOPIFY_NEW_CONNECTION: `${integrationsApps.SHOPIFY_APP}/`,
  SHOPIFY_INSTALL: `${integrationsApps.SHOPIFY_APP}/install`,
  SHOPIFY_REGISTER: `${integrationsApps.SHOPIFY_APP}/register`,
  SHOPIFY_GRANT: `${integrationsApps.SHOPIFY_APP}/grant/:page`,
}

export const facebookAppRoutes = {
  FACEBOOK_NEW_CONNECTION: `${integrationsApps.FACEBOOK_APP}`,
}
export const ecommerceAppRoutes = {
  ECOMMERCE_NEW_CONNECTION: `${integrationsApps.ECOMMERCE}/:platform`,
  ECOMMERCE_EDIT_CONNECTION: `${integrationsApps.ECOMMERCE}/:platform/:id`,
}

export const instagramAppRoutes = {
  INSTAGRAM_NEW_CONNECTION: `${integrationsApps.INSTAGRAM_APP}`,
}

export const adsManagerAppRoutes = {
  ADSMANAGER_NEW_CONNECTION: `${integrationsApps.ADSMANAGER}`,
}

export const shopifyAppGrantRoutes = {
  SHOPIFY_GRANT_PRICING: `${integrationsApps.SHOPIFY_APP}/grant/pricing`,
  SHOPIFY_GRANT_CONTINUE: `${integrationsApps.SHOPIFY_APP}/grant/continue`,
  SHOPIFY_GRANT_PAYMENTS: `${integrationsApps.SHOPIFY_APP}/grant/payments`,
  SHOPIFY_GRANT_RESET: `${integrationsApps.SHOPIFY_APP}/grant/reset`,
  SHOPIFY_GRANT_OVERDUE: `${integrationsApps.SHOPIFY_APP}/grant/overdue`,
  SHOPIFY_GRANT_SCOPE: `${integrationsApps.SHOPIFY_APP}/grant/scope`,
  SHOPIFY_GRANT_MATCHER: `${integrationsApps.SHOPIFY_APP}/grant/matcher`,
  SHOPIFY_GRANT_INSTALL_COMPLETE: `${integrationsApps.SHOPIFY_APP}/grant/installcomplete`,
}

export const billingRoutes = {
  PLAN_CREDITS: `${settingsRoutes.BILLING}/plans-credits`,
  EDIT_PLAN: `${settingsRoutes.BILLING}/plans`,
  ADD_CREDITS: `${settingsRoutes.BILLING}/credits`,
  EDIT_CARD: `${settingsRoutes.BILLING}/cards`,
  EDIT_ADDRESS: `${settingsRoutes.BILLING}/address`,
  ORDER_SUMMARY: `${settingsRoutes.BILLING}/summary`,
  WALLET: `${settingsRoutes.BILLING}`,
  USAGE_REPORTS: `${settingsRoutes.BILLING}/reports`,
  BILLING_NOTIFICATIONS: `${settingsRoutes.BILLING}/notifications`,
}

export const rulesEngineRoutes = {
  ROUTING_RULES: `${appsRoutes.RULES_ENGINE}`,
  AUTO_RULES: `${appsRoutes.RULES_ENGINE}/auto-routing`,
}

export const segmentRoutes = {
  EDITCLONE: `${appsRoutes.SEGMENTS}/:id/:action`,
  NEW: `${appsRoutes.SEGMENTS}/new`,
}

// set default route here
routes.OVERVIEW = routes.INITIAL

export default routes
