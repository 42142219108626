import config from 'lib/config'

import { INTEGRATIONTYPES } from '../../constants'

export const textMapper = {
  [INTEGRATIONTYPES.FACEBOOK]: {
    headerTitle: `integrations.connectFb`,
    desc: `integrations.facebookIntro`,
    dropDown: `integrations.selectFbPage`,
    scope: config.FACEBOOK_SCOPES,
    getListAPI: `${config.FACEBOOK_BASE_URL}/me/accounts`,
    fields: config.FACEBOOK_PAGE_FIELDS,
    connectSuccess: `integrations.toast.facebookConnectSuccess`,
    connectFail: `integrations.toast.facebookConnectFail`,
    disconnectSuccess: `integrations.toast.facebookDisconnectSuccess`,
    disconnectFail: `integrations.toast.facebookDisconnectFail`,
    connectAPI: `${config.BASE_API}/integrations?platform=${INTEGRATIONTYPES.FACEBOOK}`,
    disconnectAPI: `${config.BASE_API}/integrations?platform=${INTEGRATIONTYPES.FACEBOOK}`,
  },
  [INTEGRATIONTYPES.INSTAGRAM]: {
    headerTitle: `integrations.instagram.connectInsta`,
    desc: `integrations.instagram.intro`,
    dropDown: `integrations.instagram.selectAccount`,
    scope: config.INSTAGRAM_SCOPES,
    getListAPI: `${config.FACEBOOK_BASE_URL}/me/accounts`,
    fields: config.FACEBOOK_PAGE_FIELDS,
    connectSuccess: `integrations.toast.instagramConnectSuccess`,
    connectFail: `integrations.toast.instagramConnectFail`,
    disconnectSuccess: `integrations.toast.instagramDisconnectSuccess`,
    disconnectFail: `integrations.toast.instagramDisconnectFail`,
    connectAPI: `${config.BASE_API}/integrations?platform=${INTEGRATIONTYPES.INSTAGRAM}`,
    disconnectAPI: `${config.BASE_API}/integrations?platform=${INTEGRATIONTYPES.INSTAGRAM}`,
  },
  [INTEGRATIONTYPES.ADSMANAGER]: {
    headerTitle: `integrations.adsManager.connectAdsManager`,
    desc: `integrations.adsManager.intro`,
    dropDown: `integrations.adsManager.selectAccount`,
    scope: config.ADSMANAGER_SCOPES,
    getListAPI: `${config.FACEBOOK_BASE_URL_v15}/me/adaccounts`,
    fields: config.ADSMANAGER_FIELDS,
    connectSuccess: `integrations.toast.adsManagerConnectSuccess`,
    connectFail: `integrations.toast.adsManagerConnectFail`,
    disconnectSuccess: `integrations.toast.adsManagerDisconnectSuccess`,
    disconnectFail: `integrations.toast.adsManagerDisconnectFail`,
    connectAPI: `${config.BASE_API}/integrations/ads_manager/connect`,
    disconnectAPI: `${config.BASE_API}/integrations/ads_manager/disconnect`,
  },
}
