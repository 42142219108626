export const modules = {
  chat: 'chat',
  chatSwitch: 'chat-switch', // view as
  queues: 'queues',
  quickFindAllAccess: 'quick-find-all', // new
  flows: 'flows', // new
  templates: 'templates', // new
  popups: 'popups', // new
  broadcast: 'broadcast',
  quickReplies: 'quick-replies',
  billing: 'billing', // new
  billingReports: 'billing-reports',
  accountProfile: 'accountProfile',
  agentManagement: 'agent-management',
  teamManagement: 'team-management',
  contactManagement: 'contact-management',
  bots: 'bots',
  routing: 'routing',
  integrations: 'integrations',
  apiWebhooks: 'api-webhooks',
  apiKeys: 'api-keys', // new
  messageLogs: 'message-logs', //new
  businessMessages: 'business-messages', // new
  ordersTab: 'orders-tab',
  segments: 'segments',
  // others
  phoneNumbers: 'phone-numbers', //view phone numbers
  linkCreator: 'link-creator',
  clickToWhatsappAds: 'click-to-whatsapp-ads',
}

const salesmanLimited = [modules.chat, modules.linkCreator]
const salesman = [
  ...salesmanLimited,
  modules.queues,
  modules.phoneNumbers,
  modules.ordersTab,
  modules.chatSwitch,
  modules.contactManagement,
  modules.quickFindAllAccess,
]
const admin = [
  ...salesman,
  modules.integrations,
  modules.teamManagement,
  modules.bots,
  modules.apiWebhooks,
  modules.routing,
  modules.broadcast,
  modules.clickToWhatsappAds,
  modules.quickReplies,
  modules.templates,
  modules.popups,
  modules.flows,
  modules.chatSwitch,
  modules.agentManagement,
  modules.accountProfile,
  modules.apiKeys,
  modules.messageLogs,
  modules.businessMessages,
  modules.segments,
]
const owner = [...admin, modules.billing, modules.billingReports]
// waterfall logic of the roles and permissions

export default {
  salesman_limited: salesmanLimited,
  salesman,
  owner,
  admin,
}
