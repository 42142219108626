import { firebaseConfig as FIREBASE_DEVELOPMENT } from './firebase/dev'
import { firebaseConfig as FIREBASE_PRODUCTION } from './firebase/prod'

const getEnv = () => {
  if (window.location.hostname === 'localhost') {
    return 'local'
  }
  return process.env.REACT_APP_HOST_ENV || 'development'
}

const ENV = getEnv()

const development = {
  ENV: ENV,
  BASE_API: 'https://web.gke.zoko.dev/v3',
  MQTT_URL: 'wss://mqtt.gke.zoko.dev/mqtt',
  // BASE_API: 'https://povio.api.zoko.dev/v3',
  // MQTT_URL: 'wss://test.mqtt.zoko.dev/mqtt',
  FIREBASE: FIREBASE_DEVELOPMENT,
  UTM_SOURCE: 'zoko',
  UTM_MEDIUM: 'web_app',
  UTM_CAMPAIGN_WA: 'whatsapp',
  WA_URL_SHORTENER_API: 'https://test.gus.zoko.dev/api/v1',
  PAY_URL_SHORTENER_API: 'https://test.gus.zoko.dev/api/v1',
  URL_SHORTENER_API_2FA_SECRET_KEY: 'P3JTVCHYQ3F55QKC',
  BUGSNAG_API_KEY: '9998a9e9f8868f6565f05162e0066dc0',
  MIXPANEL: 'af9bfdbb6dc6f9e2f2e711fc5f6fce2b',
  MIXPANEL_SECONDARY: '955dd81b8089218460d02b0fc42c1e36',
  STRIPE_API_KEY:
    'pk_test_51I9plQAeE7attOCzbDscWtPULZspmbnKrKV5eCrDdHQfjtJgyQcNjavZJYDqTyArC5nCQwOBoh7fFBzEE2akjF7600HgeDaHgI',
  SYNC_API_URL: 'https://test.management.zoko.dev',
  USER_PILOT: 'NX-60eg28i2',
  SHOPIFY_API_KEY: '88e0abf447b53546e5891f8696d4ef38',
  SHOPIFY_ACCESS_SCOPES:
    'read_script_tags,write_script_tags,read_orders,read_products,read_customers,read_fulfillments,read_product_listings,read_checkouts,write_checkouts,write_orders',
  FACEBOOK_APP_ID: '404784014777310',
  FACEBOOK_APP_SECRET: 'ad6c1b9ba0a1ecc751aaa79f0ef953e5',
  FACEBOOK_BASE_URL: 'https://graph.facebook.com/v12.0',
  FACEBOOK_BASE_URL_v15: 'https://graph.facebook.com/v15.0',
  FACEBOOK_SCOPES: 'email,public_profile,pages_show_list,pages_messaging',
  INSTAGRAM_SCOPES:
    'public_profile,email,instagram_manage_messages,pages_manage_metadata,pages_messaging,instagram_basic,pages_read_engagement,pages_show_list',
  ADSMANAGER_SCOPES: `ads_read,pages_read_engagement`,
  FACEBOOK_PAGE_FIELDS:
    'picture{url},id,name,access_token,username,instagram_business_account{id,username,profile_picture_url,name}',
  ADSMANAGER_FIELDS: 'id,name,account_id',
  WHATSAPP_EXPIRY_HOURS: 24,
  MESSENGER_EXPIRY_HOURS: 168, //7 days
  DYNAMIC_LINK_API: 'https://web.gke.zoko.dev/v3/click?to=',
  WIDGET_SCRIPT_URL: 'https://atlantis.zoko.dev/scripts/main.js',
}

const povio = {
  ENV: ENV,
  BASE_API: 'https://povio.api.zoko.dev/v3',
  MQTT_URL: 'wss://test.mqtt.zoko.dev/mqtt',
  FIREBASE: FIREBASE_DEVELOPMENT,
  UTM_SOURCE: 'zoko',
  UTM_MEDIUM: 'web_app',
  UTM_CAMPAIGN_WA: 'whatsapp',
  WA_URL_SHORTENER_API: 'https://test.gus.zoko.dev/api/v1',
  PAY_URL_SHORTENER_API: 'https://test.gus.zoko.dev/api/v1',
  URL_SHORTENER_API_2FA_SECRET_KEY: 'P3JTVCHYQ3F55QKC',
  BUGSNAG_API_KEY: '9998a9e9f8868f6565f05162e0066dc0',
  MIXPANEL: 'af9bfdbb6dc6f9e2f2e711fc5f6fce2b',
  MIXPANEL_SECONDARY: '955dd81b8089218460d02b0fc42c1e36',
  STRIPE_API_KEY:
    'pk_test_51I9plQAeE7attOCzbDscWtPULZspmbnKrKV5eCrDdHQfjtJgyQcNjavZJYDqTyArC5nCQwOBoh7fFBzEE2akjF7600HgeDaHgI',
  SYNC_API_URL: 'https://test.management.zoko.dev',
  USER_PILOT: 'NX-60eg28i2',
  SHOPIFY_API_KEY: '88e0abf447b53546e5891f8696d4ef38',
  SHOPIFY_ACCESS_SCOPES:
    'read_script_tags,write_script_tags,read_orders,read_products,read_customers,read_fulfillments,read_product_listings,read_checkouts,write_checkouts,write_orders',
  FACEBOOK_APP_ID: '404784014777310',
  FACEBOOK_APP_SECRET: 'ad6c1b9ba0a1ecc751aaa79f0ef953e5',
  FACEBOOK_BASE_URL: 'https://graph.facebook.com/v12.0',
  FACEBOOK_BASE_URL_v15: 'https://graph.facebook.com/v15.0',
  FACEBOOK_SCOPES: 'email,public_profile,pages_show_list,pages_messaging',
  INSTAGRAM_SCOPES:
    'public_profile,email,instagram_manage_messages,pages_manage_metadata,pages_messaging,instagram_basic,pages_read_engagement,pages_show_list',
  ADSMANAGER_SCOPES: `ads_read,pages_read_engagement`,
  FACEBOOK_PAGE_FIELDS:
    'picture{url},id,name,access_token,username,instagram_business_account{id,username,profile_picture_url,name}',
  ADSMANAGER_FIELDS: 'id,name,account_id',
  WHATSAPP_EXPIRY_HOURS: 24,
  MESSENGER_EXPIRY_HOURS: 168, //7 days
  DYNAMIC_LINK_API: 'https://web.gke.zoko.dev/v3/click?to=',
  WIDGET_SCRIPT_URL: 'https://stratos.atlantis.zoko.dev/scripts/main.js',
}

const local = {
  ...development,
  // BASE_API: 'https://823161cdddd7.in.ngrok.io/v3',
  WIDGET_SCRIPT_URL: 'http://localhost:3030/scripts/main.js', // atlantis server must be running
}

const staging = {
  ...development,
  FACEBOOK_APP_ID: '404784014777310',
  FACEBOOK_APP_SECRET: 'ad6c1b9ba0a1ecc751aaa79f0ef953e5',
  BASE_API: 'https://stage.web.api.zoko.dev/v3',
  MQTT_URL: 'wss://mqtt.zoko.io/mqtt',
  FIREBASE: FIREBASE_PRODUCTION,
  URL_SHORTENER_API: 'https://wa.zoko.io/api/v1',
  PAY_URL_SHORTENER_API: 'https://pay.zoko.io/api/v1',
  URL_SHORTENER_API_2FA_SECRET_KEY: '6OMTZVRDWSRTB6KL',
  USER_PILOT: 'NX-60eg28i2',
  WHATSAPP_EXPIRY_HOURS: 24, // 1 day
  MESSENGER_EXPIRY_HOURS: 168, // 7 days
}

const production = {
  ...development,
  BASE_API: 'https://web3.api.zoko.io/v3',
  MQTT_URL: 'wss://mqtt.zoko.io/mqtt',
  FIREBASE: FIREBASE_PRODUCTION,
  URL_SHORTENER_API: 'https://wa.zoko.io/api/v1',
  PAY_URL_SHORTENER_API: 'https://pay.zoko.io/api/v1',
  URL_SHORTENER_API_2FA_SECRET_KEY: '6OMTZVRDWSRTB6KL',
  MIXPANEL: '7659eae208e6d70830122e00195eda71',
  MIXPANEL_SECONDARY: '67ad9f7157844adf2bbc68da9940b1fc',
  STRIPE_API_KEY:
    'pk_live_51I9plQAeE7attOCzo50v5aBqzkvJjyl4l67OxOPybp6oQzWeDXj3eSdfWyp55XPY5M1WvflyshVqowh2QSedEqnt00yyYtIDxo',
  SYNC_API_URL: 'https://management.zoko.io',
  USER_PILOT: 'NX-60eg28i2',
  SHOPIFY_API_KEY: 'a062193d60747a394630435afc6dfc74',
  FACEBOOK_APP_ID: '4504112716382182',
  FACEBOOK_APP_SECRET: 'c7769a9d9d6607a4b126766ac3822590',
  WHATSAPP_EXPIRY_HOURS: 24, // 1 day
  MESSENGER_EXPIRY_HOURS: 168, // 7 days,
  DYNAMIC_LINK_API: 'https://web3.api.zoko.io/v3/click?to=',
  WIDGET_SCRIPT_URL: 'https://atlantis.live.zoko.io/scripts/main.js',
}

const configs = {
  development,
  staging,
  production,
  local,
  povio,
}

const currentConfig = configs[ENV]

const config = {
  ENV: ENV,
  BASE_API: currentConfig.BASE_API,
  MQTT_URL: currentConfig.MQTT_URL,
  FIREBASE: currentConfig.FIREBASE,
  UTM_SOURCE: currentConfig.UTM_SOURCE,
  UTM_MEDIUM: currentConfig.UTM_MEDIUM,
  UTM_CAMPAIGN_WA: currentConfig.UTM_CAMPAIGN_WA,
  WA_URL_SHORTENER_API: currentConfig.WA_URL_SHORTENER_API,
  PAY_URL_SHORTENER_API: currentConfig.PAY_URL_SHORTENER_API,
  URL_SHORTENER_API_2FA_SECRET_KEY:
    currentConfig.URL_SHORTENER_API_2FA_SECRET_KEY,
  BUGSNAG_API_KEY: currentConfig.BUGSNAG_API_KEY,
  MIXPANEL: currentConfig.MIXPANEL,
  STRIPE_API_KEY: currentConfig.STRIPE_API_KEY,
  SYNC_API_URL: currentConfig.SYNC_API_URL,
  USER_PILOT: currentConfig.USER_PILOT,
  MIXPANEL_SECONDARY: currentConfig.MIXPANEL_SECONDARY,
  SHOPIFY_API_KEY: currentConfig.SHOPIFY_API_KEY,
  SHOPIFY_ACCESS_SCOPES: currentConfig.SHOPIFY_ACCESS_SCOPES,
  FACEBOOK_APP_ID: currentConfig.FACEBOOK_APP_ID,
  FACEBOOK_APP_SECRET: currentConfig.FACEBOOK_APP_SECRET,
  FACEBOOK_BASE_URL: currentConfig.FACEBOOK_BASE_URL,
  FACEBOOK_BASE_URL_v15: currentConfig.FACEBOOK_BASE_URL_v15,
  FACEBOOK_SCOPES: currentConfig.FACEBOOK_SCOPES,
  INSTAGRAM_SCOPES: currentConfig.INSTAGRAM_SCOPES,
  FACEBOOK_PAGE_FIELDS: currentConfig.FACEBOOK_PAGE_FIELDS,
  WHATSAPP_EXPIRY_HOURS: currentConfig.WHATSAPP_EXPIRY_HOURS,
  MESSENGER_EXPIRY_HOURS: currentConfig.MESSENGER_EXPIRY_HOURS,
  DYNAMIC_LINK_API: currentConfig.DYNAMIC_LINK_API,
  WIDGET_SCRIPT_URL: currentConfig.WIDGET_SCRIPT_URL,
  ADSMANAGER_SCOPES: currentConfig.ADSMANAGER_SCOPES,
  ADSMANAGER_FIELDS: currentConfig.ADSMANAGER_FIELDS,
}

export default config
