import PropTypes from 'prop-types'
import React from 'react'

import Images from 'common/assets/images'

import { Icon, icons } from '../Icon'
import { Image } from '../Image'
import styles from './Empty.module.scss'

export const Empty = (props) => {
  const { children = 'No results', iconSize = 'var(--p128)' } = props
  const url = props.image ? Images[props.image] : undefined
  return (
    <div className={styles.emptyBase}>
      {url ? (
        <Image url={url} className={styles.emptyImage} />
      ) : (
        <Icon
          className={styles.icon}
          source={icons.productListEmpty}
          size={iconSize}
        />
      )}
      {children}
    </div>
  )
}

Empty.propTypes = {
  children: PropTypes.node,
  iconSize: PropTypes.string,
  image: PropTypes.string,
}
